import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Paper,
  CircularProgress,
  Box,
  Grid,
  Card,
  CardContent,
  Divider,
} from '@mui/material';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import PublicIcon from '@mui/icons-material/Public';
import FlagIcon from '@mui/icons-material/Flag';
import WarningIcon from '@mui/icons-material/Warning';
import LanguageIcon from '@mui/icons-material/Language';
import DomainIcon from '@mui/icons-material/Domain';

const App = () => {
  const [ipInfo, setIpInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchIPInfo = async () => {
      try {
        const response = await axios.get('https://api.if-config.com/ip');
        setIpInfo(response.data);
      } catch (err) {
        setError('Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };
    fetchIPInfo();
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <div>
      <Helmet>
        <title>IP Information</title>
        <meta
          name="description"
          content="Display user's IP information including location, score, and user-agent."
        />
        <meta property="og:title" content="IP Information" />
        <meta
          property="og:description"
          content="Display user's IP information including location, score, and user-agent."
        />
      </Helmet>
      <Container maxWidth="md" style={{ marginTop: '50px' }}>
        <Paper elevation={3} style={{ padding: '20px' }}>
          <Typography variant="h4" gutterBottom align="center">
            IP Information
          </Typography>
          <Divider style={{ marginBottom: '20px' }} />

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Card style={{ backgroundColor: '#f5f5f5', minHeight: '100%' }}>
                <CardContent>
                  <Box display="flex" alignItems="center" marginBottom="15px">
                    <PublicIcon fontSize="large" color="primary" style={{ marginRight: '10px' }} />
                    <Typography variant="h6">General Information</Typography>
                  </Box>
                  <Typography variant="body1"><strong>IP Address:</strong> {ipInfo.ip}</Typography>
                  <Typography variant="body1"><strong>Continent:</strong> {ipInfo.continent_name}</Typography>
                  <Typography variant="body1"><strong>Country:</strong> {ipInfo.country_name}</Typography>
                  <Typography variant="body1"><strong>Region:</strong> {ipInfo.region} ({ipInfo.region_code})</Typography>
                  <Typography variant="body1"><strong>City:</strong> {ipInfo.city}</Typography>
                  <Typography variant="body1"><strong>Latitude:</strong> {ipInfo.latitude}</Typography>
                  <Typography variant="body1"><strong>Longitude:</strong> {ipInfo.longitude}</Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Card style={{ backgroundColor: '#f5f5f5', minHeight: '100%' }}>
                <CardContent>
                  <Box display="flex" alignItems="center" marginBottom="15px">
                    <FlagIcon fontSize="large" color="primary" style={{ marginRight: '10px' }} />
                    <Typography variant="h6">Additional Info</Typography>
                  </Box>
                  <Typography variant="body1"><strong>Is EU:</strong> {ipInfo.is_eu ? 'Yes' : 'No'}</Typography>
                  <Typography variant="body1"><strong>Calling Code:</strong> {ipInfo.calling_code}</Typography>
                  <Typography variant="body1"><strong>Flag:</strong> {ipInfo.emoji_flag}</Typography>
                  <Typography variant="body1"><strong>Time Zone:</strong> {ipInfo.time_zone.name} ({ipInfo.time_zone.abbr})</Typography>
                  <Typography variant="body1"><strong>Current Time:</strong> {new Date(ipInfo.time_zone.current_time).toLocaleString()}</Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card style={{ backgroundColor: '#f5f5f5' }}>
                <CardContent>
                  <Box display="flex" alignItems="center" marginBottom="15px">
                    <DomainIcon fontSize="large" color="primary" style={{ marginRight: '10px' }} />
                    <Typography variant="h6">ASN Information</Typography>
                  </Box>
                  <Typography variant="body1"><strong>ASN:</strong> {ipInfo.asn.asn}</Typography>
                  <Typography variant="body1"><strong>Name:</strong> {ipInfo.asn.name}</Typography>
                  <Typography variant="body1"><strong>Domain:</strong> {ipInfo.asn.domain}</Typography>
                  <Typography variant="body1"><strong>Route:</strong> {ipInfo.asn.route}</Typography>
                  <Typography variant="body1"><strong>Type:</strong> {ipInfo.asn.type}</Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card style={{ backgroundColor: '#f5f5f5' }}>
                <CardContent>
                  <Box display="flex" alignItems="center" marginBottom="15px">
                    <LanguageIcon fontSize="large" color="primary" style={{ marginRight: '10px' }} />
                    <Typography variant="h6">Languages</Typography>
                  </Box>
                  {ipInfo.languages.map((language, index) => (
                    <Typography key={index} variant="body1"><strong>{language.name}:</strong> {language.native}</Typography>
                  ))}
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card style={{ backgroundColor: '#f5f5f5' }}>
                <CardContent>
                  <Box display="flex" alignItems="center" marginBottom="15px">
                    <WarningIcon fontSize="large" color="primary" style={{ marginRight: '10px' }} />
                    <Typography variant="h6">Threat Information</Typography>
                  </Box>
                  <Typography variant="body1"><strong>TOR:</strong> {ipInfo.threat.is_tor ? 'Yes' : 'No'}</Typography>
                  <Typography variant="body1"><strong>iCloud Relay:</strong> {ipInfo.threat.is_icloud_relay ? 'Yes' : 'No'}</Typography>
                  <Typography variant="body1"><strong>Proxy:</strong> {ipInfo.threat.is_proxy ? 'Yes' : 'No'}</Typography>
                  <Typography variant="body1"><strong>Data Center:</strong> {ipInfo.threat.is_datacenter ? 'Yes' : 'No'}</Typography>
                  <Typography variant="body1"><strong>Anonymous:</strong> {ipInfo.threat.is_anonymous ? 'Yes' : 'No'}</Typography>
                  <Typography variant="body1"><strong>Known Attacker:</strong> {ipInfo.threat.is_known_attacker ? 'Yes' : 'No'}</Typography>
                  <Typography variant="body1"><strong>Known Abuser:</strong> {ipInfo.threat.is_known_abuser ? 'Yes' : 'No'}</Typography>
                  <Typography variant="body1"><strong>Threat:</strong> {ipInfo.threat.is_threat ? 'Yes' : 'No'}</Typography>
                  <Typography variant="body1"><strong>Bogon:</strong> {ipInfo.threat.is_bogon ? 'Yes' : 'No'}</Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
};

export default App;
